import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import usePlatform from 'hooks/usePlatform';
import Modal from 'components/Modal';
import { oss } from 'scripts/utils';
import './index.scss';

export default function Footer({ title, content }) {
  const [showQrCodeIndex, setShowQrCodeIndex] = useState(0);
  const [qrUrl, setQrUrl] = useState('');
  const [visible, setVisible] = useState(false);
  const { isPc } = usePlatform() || {};
  const is1688 = typeof window !== 'undefined' && window?.location?.pathname.startsWith('/go1688')
  const handleClose = () => {
    setVisible(false);
    document.body.style.overflow = 'auto';
    document.body.style.position = 'static';
  };

  const handleClick = (url) => {
    setQrUrl(url);
    setVisible(true);
    document.body.style.overflow = 'hidden';
  };

  return (
    <div className="footer-item-wrapper">
      <span className="footer-item-title">{title}</span>
      {content.map((item, index) =>
        item.type === 'Code' ? (
          <div key={index} className="footer-item-img">
            <span
              key={index}
              className="footer-item-txt"
              onClick={!isPc ? () => handleClick(item.url) : undefined}
              onMouseEnter={isPc ? () => setShowQrCodeIndex(index) : undefined}
            >
              {item.title}
            </span>
            <img
              className="content-img"
              src={oss(item.url, 300)}
              alt={item.title}
              title={item.title}
              style={showQrCodeIndex === index ? { display: 'block' } : {}}
            />
          </div>
        ) : /^https?:\/\//.test(item.url) ? (
          <a key={index} className="footer-item-txt" href={item.url} target="_blank" rel="noopener noreferrer">
            {item.title}
          </a>
        ) : (
          <Link key={index} className="footer-item-txt" to={item.url}>
            {item.title}
          </Link>
        )
      )}
      {title === '快速链接' && (
        <a key='index' className="footer-item-txt" href='https://www.daqihui.com'rel="noopener noreferrer">
          大企汇
        </a>
      )}
      <Modal visible={visible} onClose={handleClose} title="扫一扫">
        <div className="modal-qrcode-wrapper">
          <img src={qrUrl} alt="" />
        </div>
      </Modal>
    </div>
  );
}
